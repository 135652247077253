<template>
  <div class="form_forgot-password">
    <el-main>
      <div class="pl-20 pr-20 mt-3">
        <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-position="top"
                 v-if="!sendMail"
                 class="demo-ruleForm demo-form"
                 @keyup.enter.native="onSubmit"
        >

          <el-form-item prop="email">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">パスワード再設定</h3>
            <br>
            <span class="content-forgot-password">会員登録いただいた際のメールアドレスを入力し、「送信」のボタンをクリックしてください。<br>
              パスワード再設定用URLを送信します。</span>
            <br><br>
            <label><span class="star">*</span> メールアドレス</label>
            <el-input class="d-none bg-gray-item" type="text"></el-input>
            <el-input class="bg-gray-item col-7 input-mobie" v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-button class="pl-10 pr-10" type="primary" @click="onSubmit" :disabled="disable">送信</el-button>
          <el-button class="pl-10 pr-10 btn-back" @click="$router.push('login')">戻る</el-button>
        </el-form>
        <div v-if="sendMail">
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">メール送信済み</h3>
          <br>
          <br>
          <h5>パスワード再設定ページへのURLを記載したメールを送信しましました。<br>
            メールが届かない場合、迷惑メールフォルダーに届いていないかご確認ください。</h5>
          <div class="d-flex mt-20">
            <el-button class="pl-15 pr-15 btn-back" @click="$router.push('login')">戻る</el-button>
          </div>
        </div>
      </div>
    </el-main>

    <Popup id="PopupError" :content="errorMessage" :title_button="close_popup"></Popup>
  </div>
</template>


<script>
import apiService from "@/core/services/api.service";
import {FORGOT_PASSWORD} from "../../../core/services/store/auth.module";
import Popup from "@/view/components/Popup";

export default {
  name: "ForgotPassword",
  components: {Popup},

  data() {
    return {
      ruleForm: {
        email: ''
      },
      rules: {
        email: [
          {required: true, message: this.$t("RULE_FORM.mail_required"),  trigger: "change",},
          {type: 'email', message: this.$t("email_format"), trigger: [ 'change']}
        ]
      },
      sendMail: false,
      errorMessage: "",
      close_popup: this.$t("OK"),
      disable:false,
    }
  },
  watch: {
    "ruleForm.email": function (newData) {
      this.ruleForm.email = this.ruleForm.email.replace(/\s+/g, '');
    }
  },
  methods: {
    async onSubmit() {
      this.$refs['ruleForm'].validate(async (valid) =>  {
        if (valid) {
            this.disable = true;
          var email = this.ruleForm.email;
          let params = {email: email}
          if (this.$route.params.tenant_dir){
            params.tenant_dir = this.$route.params.tenant_dir
          }
          await this.$store.dispatch(FORGOT_PASSWORD, params)
              .then((response) => {
                if (!response.success) {
                  let object = response.data;
                  for (const property in object) {
                    for (let i = 0; i < object[property].length; i++) {
                      this.errorMessage = object[property][i];
                      break;
                    }
                  }
                  if (this.errorMessage) {
                    this.$bvModal.show('PopupError')
                  }
                } else {
                  this.sendMail = true
                }
                  this.disable = false;
              })
              .catch(e => {
                this.loading = false
                this.notifyError(e)
              })
            this.disable = false;
        }
      })
    }
  },

}
</script>

<style scoped>
.form_forgot-password {
  width: 80%;
  margin: 0 auto;
}

.header-form__forgot-password .title {
  background: #8c939d;
  color: white;
  text-align: center;
  padding: 4px 0;
  font-size: 20px;
}

.header-form__forgot-password .logo {
  background: #40659d;
  padding: 15px;
}

.el-form-item__content {
  margin-left: 0px;
}

.pl-20{
   padding-left: 0 !important;
   padding-right: 0 !important;

}

.mt-3{
  margin-top: 0 !important;
}
.star{
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
@media only screen and (max-width: 920px) and (min-width: 320px) {
  .input-mobie {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100% !important;
  }
  .content-forgot-password{
    font-size: 12px;
  }
}

</style>
